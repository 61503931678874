import * as Scroll from 'react-scroll'
import * as S from './AlphabetList.styled'
const Link = Scroll.Link

export interface AlphabetListProps {
  data: string[]
}

const AlphabetList = ({ data }: AlphabetListProps) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  return (
    <S.AlphabetList>
      {alphabet.map(it => (
        <Link
          activeClass='active'
          to={it}
          spy={true}
          offset={-80}
          smooth={true}
          hashSpy={true}
          isDynamic={true}
          key={it}
        >
          <S.Item disabled={data.indexOf(it) === -1}>{it}</S.Item>
        </Link>
      ))}
    </S.AlphabetList>
  )
}

export default AlphabetList
