import * as G from '../../styles/global.styles'
import * as S from './GymSearchCard.styles'
import { LocationCardProps } from '../../services/api/types'
import { LocationCard } from '../cards'
import { BlockTitle } from '../base'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { getAllGyms } from '../../services/api/cms'
import {
  generalItemsPerPage,
  PAGE_TEMPLATE
} from '../../services/api/constants'
import { Col, Row } from '../../styles/grid.styles'
const LocationSearchForm = dynamic(
  () => import('../search/LocationSearchForm'),
  { ssr: false }
)

export const GymSearchCard = (props: any) => {
  const { title, template, suburbStep, handleSuburbStep, gym } = props
  const [gyms, setGyms] = useState([])
  // let timeoutId: number | undefined = undefined

  let timeoutId: ReturnType<typeof setTimeout> | undefined

  const onQueryChange = async (search: string) => {
    const listGym = await localStorage.getItem('list_gym')
    const fullListGym = listGym ? JSON.parse(listGym) : []

    if (search == '') {
      setGyms(fullListGym)
    }
    timeoutId && clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      getAllGyms({
        ordering: 'title',
        per_page: generalItemsPerPage,
        q: search
      }).then(result => {
        setGyms(result.results)

        if (search == '') {
          localStorage.setItem('list_gym', JSON.stringify(result.results))
        }
      })
    }, 300)
  }
  return (
    <G.Container>
      <S.Container template={template}>
        {title && (
          <BlockTitle
            textTransform='uppercase'
            isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
          >
            {title}
          </BlockTitle>
        )}
        <S.FormWrapper>
          <LocationSearchForm
            searchLabel='Find your local Plus Fitness'
            searchPlaceHolder='Enter text'
            searchOnTyping={true}
            onQueryChange={onQueryChange}
            handleSuburbStep={handleSuburbStep}
            suburbStep={suburbStep}
            template={template}
            gym={gym}
          />
        </S.FormWrapper>

        {!suburbStep && (
          <S.SearchContainer>
            <Row gutter={10}>
              {gyms &&
                gyms?.map((gym: LocationCardProps, idx: number) => (
                  <Col key={idx} md={6} style={{ marginBottom: 20 }}>
                    <LocationCard
                      {...gym}
                      full_url={gym.join_url || gym.full_url}
                      linkTarget='_blank'
                      template={template}
                    />
                  </Col>
                ))}
            </Row>
          </S.SearchContainer>
        )}
      </S.Container>
    </G.Container>
  )
}

export default GymSearchCard
