import { UrlBuilder } from '../utils'
import { apiCall } from './helpers'
import { apis } from './'
import { generalItemsPerPage } from './constants'
import { WagtailPageProps, SearchGymsParams } from './types'
import { SiteProps } from '../context/SiteContext'
import { getCookie } from './cookies'

export const getWagtailPageBySlug = async (
  slug: string | string[],
  urlParams: { [key: string]: string | string[] },
  ctx: any
) => {
  let pageData: WagtailPageProps = await apiCall(
    UrlBuilder(apis.WAGTAIL_SLUG_FIND, {
      html_path: slug,
      ...urlParams
    }),
    null,
    ctx
  )
  let parentSlug: string = ''
  const currentSlug: string = decodeURIComponent(slug.toString())
  if (currentSlug.includes('/')) {
    if(getCookie(encodeURIComponent(slug.toString()), ctx)) {
      parentSlug = encodeURIComponent(slug.toString())
    }else {
      parentSlug = currentSlug.substring(0, currentSlug.indexOf('/'))
    }
  } else parentSlug = currentSlug

  if (getCookie(parentSlug, ctx)) {
    pageData = await apiCall(
      UrlBuilder(`${apis.WAGTAIL_API_BASE}${slug}`, {
        ...urlParams,
        password: getCookie(parentSlug, ctx)
      }),
      null,
      ctx
    )
  }

  const wagtailPageType =
    pageData.meta && pageData.meta.type && pageData.meta.type.split('.')[1]
  return {
    pageData,
    wagtailPageType
  }
}

export const getNavigation = async (ctx: any = null) => {
  return await apiCall(UrlBuilder(apis.MAIN_MENU_API, null), null, ctx)
}

export const getFlatMenu = async (ctx: any = null) => {
  return await apiCall(UrlBuilder(apis.FLAT_MENU_API, null), null, ctx)
}

export const getForm = async (ctx: any = null, params: any = null) => {
  return await apiCall(UrlBuilder(apis.FORMS_API, params), null, ctx)
}

export const submitForm = async (ctx: any = null, slug: string, data: any) => {
  const formData = {
    slug: slug,
    fields: data
  }
  return await apiCall(apis.FORMS_API, formData, ctx, 'post')
}

export const submitFormWithFiles = async (
  ctx: any = null,
  slug: string,
  data: any,
  files: any
) => {
  const formData = {
    slug: slug,
    fields: JSON.stringify(data),
    ...files
  }
  return await apiCall(apis.FORMS_API, formData, ctx, 'post', true)
}

export const submitWorldlineForm = async (
  ctx: any = null,
  slug: string,
  data: any
) => {
  const formData = {
    slug: slug,
    fields: data
  }
  return await apiCall(apis.WORLDLINE_FORMS_API, formData, ctx, 'post')
}

export const getFormCMSContent = async (ctx: any = null, params: any) => {
  const formRes = await getForm(ctx, params)
  if (formRes && formRes.results && formRes.results.length > 0) {
    const formData = formRes.results[0]
    delete formData.title
    return {
      ...formData
    }
  }

  return {}
}

export const getWagtailPreview = async (
  contentType: string,
  previewToken: string
) => {
  const pageData: WagtailPageProps = await apiCall(
    UrlBuilder(apis.WAGTAIL_PREVIEW, {
      content_type: contentType,
      token: previewToken
    }),
    null
  )

  const wagtailPageType =
    pageData.meta && pageData.meta.type && pageData.meta.type.split('.')[1]
  return {
    pageData,
    wagtailPageType
  }
}

export const getChildPages = async (
  type: string,
  fields: string[],
  otherOptions = {}
) => {
  let options = {
    type: type,
    fields: fields || '*',
    ...otherOptions
  }
  return await apiCall(UrlBuilder(apis.WAGTAIL_API_BASE, options))
}

export const getSiteSettings = async (ctx: any) => {
  const { sitesettings, youplusfitnesssettings } = await apiCall(apis.CMS_SETTINGS, null, ctx)
  return {sitesettings, youplusfitnesssettings}
}

export const getSiteInfors = async (
  ctx: any,
  queryParam: string = '?current'
) => {
  return await apiCall(`${apis.SITE}${queryParam}`, null, ctx)
}

export const getGyms = async (params: SearchGymsParams) => {
  return await apiCall(UrlBuilder(apis.GYMS_API_BASE, params))
}

export const getGymsFromSite = async (
  params: SearchGymsParams,
  site: SiteProps
) => {
  return await apiCall(
    UrlBuilder(apis.GYMS_API_BASE, { ...params, site: site.hostname })
  )
}

export const getAllGyms = async (params: SearchGymsParams, ctx: any = null) => {
  let gymResults = await apiCall(
    UrlBuilder(apis.GYMS_API_BASE, params),
    null,
    ctx
  )

  while (gymResults.next !== null) {
    let res = await apiCall(
      UrlBuilder(apis.GYMS_API_BASE, {
        ...params,
        page: Math.ceil(gymResults?.results?.length / generalItemsPerPage) + 1
      }),
      null,
      ctx
    )

    gymResults = {
      ...res,
      results: [...gymResults.results, ...res.results]
    }
  }

  return gymResults
}

export const getAllOpportunities = async (
  params: SearchGymsParams,
  ctx: any = null
) => {
  let opportunitiesResults = await apiCall(
    UrlBuilder(apis.OPPORTUNITIES_API_BASE, params),
    null,
    ctx
  )

  while (opportunitiesResults.next !== null) {
    let res = await apiCall(
      UrlBuilder(apis.OPPORTUNITIES_API_BASE, {
        ...params,
        page:
          Math.ceil(
            opportunitiesResults?.results?.length / generalItemsPerPage
          ) + 1
      }),
      null,
      ctx
    )

    opportunitiesResults = {
      ...res,
      results: [...opportunitiesResults.results, ...res.results]
    }
  }

  return opportunitiesResults
}

export const getPosts = async (queryParams: any, ctx?: any) => {
  return await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, queryParams),
    null,
    ctx
  )
}

export const getAuthorById = async (authorId: number = 0, ctx?: any) => {
  return await apiCall(
    UrlBuilder(`${apis.WAGTAIL_API_BASE}${authorId}`, null),
    null,
    ctx
  )
}

export const getCorporate = async (queryParams: any, ctx?: any) => {
  return await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, queryParams),
    null,
    ctx
  )
}

export const getClasses = async (pageId: number, ctx?: any) => {
  return await apiCall(
    UrlBuilder(`${apis.WAGTAIL_API_BASE}${pageId}`, null),
    null,
    ctx
  )
}

export const getClassData = async (queryParams: any, ctx?: any) => {
  return await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, queryParams),
    null,
    ctx
  )
}
