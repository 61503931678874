import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../styles/theme'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const Container = styled.div`
  ${tw`container mx-auto flex flex-wrap justify-between items-center py-2 px-2`}
`

export const ContainerCustom = styled.div<{
  showBoxShadow?: boolean
}>`
  ${tw`flex justify-between items-center w-full`}
  ${`
      padding: 8px 40px;
  `}
  
  ${({ showBoxShadow }) => {
    if (showBoxShadow) {
      return `box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);`
    }
  }}

  /* Extra Large (xl) */
  @media (max-width: 480px) {
    padding: 16px 16px;
  }
`

export const JoinButton = styled.a`
  ${tw`uppercase border border-orange px-4 py-2 font-bold text-white hover:bg-orange transition-colors duration-300`}
  line-height: 1.75;
`

export const EnquireButton = styled.a`
  ${tw`uppercase border border-blue__dark px-4 py-2 font-bold text-white hover:bg-blue__dark transition-colors duration-300`}
`

export const EnquireButtonWhite = styled.a`
  ${tw`bg-white text-black transition-colors duration-300`}
  line-height: 2.5;
  padding: 0.35rem 2rem;
  font-weight: 500;
  margin-right: ${size(16)};
  ${fontSize(14)}

  &:hover {
    background-color: #cccccc;
  }

  /* Extra Large (xl) */
  @media (max-width: 1279px) {
    display: none;
  }
`

export const WrapEnquireJoinNow = styled.div`
  ${tw`flex items-center`}
`

export const NavigationWrapper = styled.div<{
  navState?: 'relative' | 'fixed'
  template?: LAYOUT_TEMPLATE
}>`
  z-index: 51;
  ${tw`w-full transition-all duration-300 bg-blue top-0`}

  ${({ navState, template }) => {
    switch (navState) {
      case 'relative':
        return template == PAGE_TEMPLATE.YOU_FITNESS
          ? tw`relative bg-black__deep`
          : tw`relative lg:bg-blue`
      case 'fixed':
        return template == PAGE_TEMPLATE.YOU_FITNESS
          ? tw`fixed bg-black__deep`
          : tw`fixed lg:bg-blue`
      default:
        return template == PAGE_TEMPLATE.YOU_FITNESS
          ? tw`relative bg-black__deep`
          : tw`fixed lg:bg-transparent`
    }
  }}

  ${JoinButton} {
    ${({ navState, template }) => {
      switch (navState) {
        case 'relative':
          return template == PAGE_TEMPLATE.YOU_FITNESS
            ? tw`border-white text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`
            : tw`text-white hover:bg-orange hover:text-white border-white hover:border-transparent`
        case 'fixed':
          return template == PAGE_TEMPLATE.YOU_FITNESS
            ? tw`border-white text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`
            : tw`text-white hover:bg-orange hover:text-white border-white hover:border-transparent`
        default:
          return template == PAGE_TEMPLATE.YOU_FITNESS
            ? tw`border-white text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`
            : tw`border-white text-white hover:bg-orange__dark hover:border-orange__dark hover:text-white xl:bg-orange xl:border-orange`
      }
    }}
  }
  
  ${EnquireButton} {
    ${({ navState }) => {
      switch (navState) {
        case 'relative':
          return tw`text-white hover:bg-white hover:text-blue border-white hover:border-transparent`
        case 'fixed':
          return tw`text-white hover:bg-white hover:text-blue border-white hover:border-transparent`
        default:
          return tw`border-white text-white hover:bg-blue__dark hover:border-blue__dark hover:text-white xl:bg-blue xl:border-blue`
      }
    }}
  }
  
`

export const HeaderWrapper = styled.div<{
  showHeader: boolean
  template?: LAYOUT_TEMPLATE
}>`
  ${tw`hidden w-full`}
  ${({ showHeader, template }) => {
    return showHeader
      ? template == PAGE_TEMPLATE.YOU_FITNESS
        ? tw`block`
        : tw`lg:block`
      : ''
  }}
`

export const MobileMenus = styled.div`
  ${tw`max-h-full w-full overflow-y-auto flex flex-col`}
`

export const MobileWrapper = styled.div`
  ${tw`fixed top-0 right-0 bottom-0 left-0 flex flex-col justify-between z-50`}
  &.hidden {
    display: none;
  }
`

export const WrapMenuMobile = styled.div`
  ${tw`flex`}
`

export const WrapLogo = styled.a`
  width: 100%;
  max-width: 121px;
  height: 44px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 360px) {
    max-width: 110px;
  }
`

export const DesktopLogo = styled.img<{ menuPosition?: string }>`
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* Extra Large (xl) */
  @media (max-width: 1279px) {
    display: none;
  }
`

export const Logo = styled.img<{ menuPosition?: string }>`
  max-height: 65px;
  max-width: 65px;
  @media (min-width: 640px) {
    max-height: 70px;
    max-width: 70px;
  }

  /* Medium (md) */
  @media (min-width: 768px) {
  }

  /* Large (lg) */
  @media (min-width: 1024px) {
    max-height: 80px;
    max-width: 80px;
  }

  /* Extra Large (xl) */
  @media (min-width: 1280px) {
  }
  ${tw`hidden w-1/4 lg:w-auto xl:block`};
  ${({ menuPosition }) =>
    !menuPosition
      ? css`
          @media (min-width: 1024px) {
            max-height: 90px;
            max-width: 90px;
          }
        `
      : ''}
`

export const MobileMenusWrapper = styled.div`
  ${tw`w-full flex-1 overflow-y-hidden`}
`

export const MobileActions = styled.div`
  ${tw`w-full`}
`

export const MobileActionsNew = styled.div<{ showHeader: boolean }>`
  ${tw`w-full`}
  display: none;

  ${({ showHeader }) => {
    return showHeader ? tw`block` : ''
  }}
`

export const MobileHeader = styled.div`
  ${tw`w-full lg:hidden`}
`

export const MobileButtonWrapper = styled.div`
  ${tw`my-4 p-2 text-center`}
`

export const MobileLinks = styled.div`
  ${tw`flex flex-col text-center`}
`

export const MobileLink = styled.a`
  ${tw`py-3 font-semibold text-white hover:text-orange transition-colors duration-300 text-xl`}
`

export const MobileMenuButton = styled.button`
   ${tw`xl:hidden italic text-2xl`}
   ${({ color }) => color == 'orange' && tw`text-orange`}
   ${({ color }) => (!color || color == 'white') && tw`text-white`}
`

export const MobileLogo = styled.img`
  max-height: 65px;
  max-width: 65px;

  /* Large (lg) */
  @media (min-width: 1024px) {
    max-height: 80px;
    max-width: 80px;
  }

  /* Extra Large (xl) */
  @media (min-width: 1280px) {
  }
  ${tw`lg:w-auto xl:hidden`};
`

export const Menu = styled.a`
  ${tw`py-2 px-2 text-center font-bold text-xl text-white hover:text-orange transition-colors duration-300`}
`

export const HeaderGroup = styled.div`
  ${tw`w-full flex flex-row justify-between transition-all duration-300 bg-blue`}
`

export const DesktopMenus = styled.div`
  ${tw`hidden xl:flex lg:flex-wrap flex-1 justify-between pl-12`}
`
