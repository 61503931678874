import { STATE_OPTIONS } from './api/constants'
import { IconEnums } from '../../src/components/base'
import logo_white from '../../static/example/logo_white.png'
import logo_plus from '../../static/example/logo_plus.png'
import icon_footer from '../../static/example/icon_footer.png'
import viva_logo from '../../static/example/viva_leisure.png'
import logo_blue from '../../static/example/logo.png'
import fionaAvatarImg from '../../static/example/Fiona.jpg'
import locationMarker from '../../src/static/imgs/location-marker.png'
import { FooterProps } from '../../src/components/footer/Footer'
import { NavigationProps } from '../../src/components/navigation/Navigation'
import { HeroProps } from '../../src/components/hero'
import { MenuBlocksProps } from '../../src/components/menu'
import { BannerProps } from '../../src/components/banner'
import { NewsletterProps } from '../../src/components/newsletter'
import { ArticleListProps } from '../components/whats-hot'
import { HeaderWithTitleProps } from '../components/header'
import { MembershipBenefitsProps } from '../components/membership-benefits'
import { LocationMapProps } from '../components/maps/LocationMap'
import {
  VideoBlocksProps,
  ShareBlockProps,
  PersonBlockProps,
  ReadyStartedBlockProps
} from '../components/blocks'
import { OwnerEnquireFormProps } from '../components'
import { ArticleCardProps } from '../components/whats-hot/cards'

export const sampleFooterProps: FooterProps = {
  logo: logo_white,
  logoNew: logo_plus,
  iconFooter: icon_footer,
  partner: viva_logo,
  partnerLink: 'https://www.vivaleisure.com.au/',
  copyright: `Copyright © ${new Date().getFullYear()}  Plus Fitness. All rights reserved.`,
  siteLinks: [
    { label: 'Plus Fitnees India', url: '#' },
    { label: 'Plus Fitnees New Zealand', url: '#' }
  ],
  links: [
    { label: 'Terms and Conditions', url: '#' },
    { label: 'Advertise with us', url: '#' },
    { label: 'Privacy Policy', url: '#' },
    { label: 'Login', url: '#' }
  ],
  social: {
    label: 'Follow Us',
    socialLinks: [
      { icon: IconEnums['facebook-filled'], url: '#' },
      { icon: IconEnums['threads-filled'], url: '#' },
      {
        icon: IconEnums['instagram-filled'],
        url: '#'
      },
      { icon: IconEnums['youtube-filled'], url: '#' }
    ],
    newSocialLinks: [
        { icon: IconEnums['icon-fb2'], url: '#' },
        {
          icon: IconEnums['icon-instagram2'],
          url: '#'
        },
        { icon: IconEnums['icon-youtube2'], url: '#' }
      ]
  }
}

export const navigationSampleProps: NavigationProps = {
  blueLogo: logo_blue,
  whiteLogo: logo_white,
  headerProps: {
    CTATitle: 'Find your local Plus Fitness',
    Titles: ['24/7 gym -', 'no lock in contracts'],
    buttonLabel: 'Search',
    buttonAction: '#',
    searchInputProps: {
      placeholder: 'Type your suburb or post code'
    }
  },
  links: [
    {
      url: '#',
      label: 'Find a Gym'
    },
    {
      url: '#',
      label: 'Memberships'
    },
    {
      url: '#',
      label: 'Our Classes'
    },
    {
      url: '#',
      label: 'Own a Gym'
    },
    {
      url: '#',
      label: 'Careers'
    },
    {
      url: '#',
      label: 'Plus Performance'
    }
  ],
  joinButton: {
    label: 'Join now',
    href: '#'
  }
}

export const newsProps: HeroProps = {
  slides: [
    {
      bgColor: 'orange',
      bgImgURL: 'static/example/home-hero.png',
      contentPosition: 'left',
      overlay: true,
      contentWidth: '2/3',
      texts: [
        {
          color: 'silver',
          text: `MARCH 21ST, 2018 IN WELLNESS BY JASMINE GREEN`,
          fontWeight: 'semibold',
          size: '2xl',
          textCase: 'upper',
          spaceBottom: 0.5
        },
        {
          color: 'white',
          text: `GETTING FIT: WHAT YOU NEED TO KNOW ABOUT BMI & BODY FAT`,
          fontWeight: 'bold',
          size: '4xl',
          textCase: 'upper'
        },
        {
          color: 'silver',
          text: `If you've been trying to lose weight, get healthier, or just got a checkup from your doctor recently then you've propbaly heard about BMI or Body Mass Index`,
          fontWeight: 'bold',
          spaceTop: 2,
          spaceBottom: 3
        }
      ],
      buttons: [
        {
          href: '#',
          color: 'orange',
          label: 'Read'
        }
      ]
    }
  ]
}

export const menuProps: MenuBlocksProps = {
  menuItems: [
    { label: 'All Categories', url: '#' },
    { label: 'Fitness', url: '#' },
    { label: 'Nutrition', url: '#' },
    { label: 'Wellness', url: '#' },
    { label: 'Other 1', url: '#' },
    { label: 'Other 2', url: '#' }
  ]
}

export const bannerProps: BannerProps = {
  imageURL: 'static/example/banner-bg-example.png',
  title: 'Promotion',
  content: 'Free week of fitness',
  action: {
    label: 'Enquire now',
    url: '#'
  }
}

export const newsletterProps: NewsletterProps = {
  newsletterTitle: `STAY INFORMED`,
  newsletterMsg: `Register for regular updates, blogs and Plus Fitness news`,
  subBtnText: `SUBSCRIBE TO THE NEWSLETTER`,
  successTitle: 'Newsletter Success',
  successMessage: '',
  errorTitle: 'Newsletter Error',
  errorMessage: ''
}

export const articleList: ArticleListProps = {
  showSortDropdown: true,
  listTitle: 'SEE WHAT ELSE IS NEW...',
  pagingPerPage: 5,
  totalItemsCount: 10,
  activePage: 1,
  articles: [
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    },
    {
      title: 'In fitness by Jasmine Green',
      content: `3 reason to add strength training`,
      action: { label: 'Read article', url: '#' },
      imgURL: 'static/example/Social-card-article-bg.png',
      isReskew: false
    }
  ]
}

export const shareProps: ShareBlockProps = {
  shareObj: {
    image: 'https://staging.plusfitness.com.au/media/images/Members_1.2e16d0ba.fill-1000x600.width-1200.jpg',
    shareUrl: 'https://staging.plusfitness.com.au/blog/six-top-benefits-training-buddy/',
    description: 'Six Top Benefits of Training With a Buddy',
    name: 'Six Top Benefits of Training With a Buddy'
  }
}

export const authorProps: PersonBlockProps = {
  avatarURL: fionaAvatarImg,
  name: 'Jasmine Green',
  bios:
    'Certificate 3 & 4 in fitness -Punch-fit boxing trainer -Bio-print practitioner',
  excert:
    'Jasmine is a transformation specialist and has over 5 years experience in the fitness industry as a personal trainer.',
  intro: `My first contact with Plus Fitness 24/7 was as a long time member of their health clubs, which have been around for many years and were well known in the Macarthur region. 
    I have spent my career in business banking and was fortunate enough to have Plus Fitness 24/7 as a client when they made the decision to develop the brand as a franchise. 
    It was very exciting seeing the process and watch the company sell it's first franchise,
    and before I knew it they had sold their 50th and so on. I had a relationship with some of the early franchisees and they had such great things to say about their experiences, 
    I developed a keen interest in the model and contemplated jumping ship many times from business banker to business owner.`
}

export const relatedPosts: ArticleCardProps[] = [
  {
    title: 'In fitness by Jasmine Green',
    content: `3 reason to add strength training`,
    action: { label: 'Read article', url: '#' },
    imgURL: 'static/example/Social-card-article-bg.png',
    isReskew: false
  },
  {
    title: 'In fitness by Jasmine Green',
    content: `3 reason to add strength training`,
    action: { label: 'Read article', url: '#' },
    imgURL: 'static/example/Social-card-article-bg.png',
    isReskew: false
  },
  {
    title: 'In fitness by Jasmine Green',
    content: `3 reason to add strength training`,
    action: { label: 'Read article', url: '#' },
    imgURL: 'static/example/Social-card-article-bg.png',
    isReskew: false
  }
]

export const videoProps: VideoBlocksProps = {
  leftThumbnailURL: 'static/example/videos-example.png',
  leftVideoTitle: `BECOME A MEMBER`,
  leftVideoURL: 'https://www.youtube.com/embed/y8Ok76X15Xk',
  leftVideoDesc: `orem ipsum dolor sit amet, consectetur<br />adipiscing elit, sed do eiusmod tempor`,
  rightThumbnailURL: 'static/example/heroImg-example.jpg',
  rightVideoTitle: 'OWN A GYM',
  rightVideoURL: 'https://www.youtube.com/embed/ucESGeAlUk4',
  rightVideoDesc: `Kathy Cummings<br />Plus fitness franchisee`,
  isShowTwoVideo: false
}

export const gymStartedBlockProps: ReadyStartedBlockProps = {
  blocks: [
    {
      text: 'Download<br/>E-brochure',
      imgURL: 'static/example/Ready-started-blocks/icon-brochure.png',
      color: 'blue-dark',
      link: '#'
    },
    {
      text: 'Find a<br/> Franchise',
      imgURL: 'static/example/Ready-started-blocks/icon-find.png',
      color: 'blue',
      link: '#'
    },
    {
      text: 'Enquire Now',
      imgURL: 'static/example/Ready-started-blocks/icon-enquire.png',
      color: 'orange',
      link: '#'
    }
  ]
}

export const readyToStartTitle: HeaderWithTitleProps = {
  headerText: 'Ready to get started ?',
  titleText: `TO FIND OUT MORE ABOUT OWNING A PLUS FITNESS SIMPLY SUBMIT YOUR DETAILS HERE AND WE
  WILL BE IN TOUCH TO ANSWER ANY INITIAL QUESTIONS`
}

export const servicesAndSupports: MembershipBenefitsProps = {
  title: 'All Services and Support Provided',
  text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et`,
  benefits: [
    {
      name: 'Full range of commercial grade strength & cardio gym',
      icon: 'static/example/benefits/strength-cardio-equipment.original.png'
    },
    {
      name: 'Aesthetic fit-out including painting',
      icon: 'static/example/benefits/unlimited-virtual-classes.png'
    },
    {
      name: 'Accesss control and remote monitoring system',
      icon: 'static/example/benefits/24-7.original.png'
    },
    {
      name: 'External and internal signage',
      icon: 'static/example/benefits/no-lock-in-contracts.original.png'
    },
    {
      name: 'Full it setup',
      icon: 'static/example/benefits/free-fitness-program.original.png'
    },
    {
      name: 'Office and gym furniture',
      icon: 'static/example/benefits/access.original.png'
    }
  ]
}

export const defaultHeroSetting: HeroProps = {
  slides: [
    {
      bgImgURL: 'static/example/home-hero.png',
      bgColor: 'orange',
      contentPosition: 'left',
      overlay: true,
      contentWidth: '1/2',
      texts: [
        {
          text: 'OWN A PLUS',
          color: 'white',
          fontWeight: 'bold',
          size: '6xl',
          textCase: 'upper'
        },
        {
          text: 'FITNESS GYM',
          color: 'white',
          fontWeight: 'bold',
          size: '6xl',
          textCase: 'upper'
        }
      ]
    }
  ]
}

export const defaultHeroFormProps: OwnerEnquireFormProps = {
  id: 0,
  slug: 'enquire-form',
  title: 'Owner Enquire',
  formHeader:
    'Enter your details below to find out more about owning a Plus Fitness gym',
  submitText: 'ENQUIRE NOW'
}

export const gymLocationData = [
  {
    geometry: { coordinates: [149.165098, -35.333169] },
    pageURL: 'canberra',
    suburb: 'Canberra'
  },
  {
    geometry: { coordinates: [148.165098, -35.333169] },
    pageURL: 'tumut',
    suburb: 'Tumut'
  },
  {
    geometry: { coordinates: [149.165098, -36.333169] },
    pageURL: 'cooma',
    suburb: 'Cooma'
  },
  {
    geometry: { coordinates: [150.165098, -35.433169] },
    pageURL: 'brooman',
    suburb: 'Brooman'
  },
  {
    geometry: { coordinates: [149.555098, -33.333169] },
    pageURL: 'organge',
    suburb: 'Organge'
  },
  {
    geometry: { coordinates: [149.365098, -35.933169] },
    pageURL: 'bredbo',
    suburb: 'Bredbo'
  },
  {
    geometry: { coordinates: [149.465098, -32.333169] },
    pageURL: 'mudgee',
    suburb: 'Mudgee'
  }
]
export const gymLocationMapProps: LocationMapProps = {
  pinIcon: locationMarker,
  mapData: gymLocationData,
  displaySub: {
    geometry: { coordinates: [149.165098, -35.333169] },
    pageURL: 'canberra',
    suburb: 'Canberra'
  }
}
export const locationSearchFormProps = {
  locationOptions: [...STATE_OPTIONS],
  searchPlaceHolder: 'Enter a suburb or postcode',
  searchButtonText: 'Go'
}
export const mediaList = [
  {
    date: '24 June 2020',
    url: '/plus-fitness-media/another-plus-fitness-victoria/',
    title: 'Another Plus Fitness For Victoria',
    docUrl: '#'
  },
  {
    date: '24 June 2020',
    url:
      '/plus-fitness-media/plus-fitness-hits-ground-running-three-new-clubs-across-two-states/',
    title:
      ' Plus Fitness Hits The Ground Running With Three New Clubs Across Two States',
    docUrl: '#'
  }
]
export const mediaFooterContent = `
    <ul>
      <li>All media enquiries can be directed to Plus Fitness Head Office</li>
      <li>Phone: +61 2 4648 2099</li>
      <li>Email: media@plusfitness.com.au</li>
    </ul>`
export const membershipEnquireButton = {
  label: 'Enquire',
  href: '/memberships/',
  target: '_self'
}
