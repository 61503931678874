import styled from 'styled-components'
import tw from 'twin.macro'
import TextInput from './TextInput'
import { InputHTMLAttributes } from 'react'

const FormLabel = styled.label`
  ${tw`text-blue__dark font-medium inline-flex items-center ml-6 my-2`}
`
const RadioInput = styled(TextInput)`
  min-width: 40px;
`
const Label = styled.span`
  ${tw`ml-2`}
`
interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  borderColor?: 'white' | 'orange' | 'black' | 'red'
}
export const Radio = (props: RadioProps) => {
  const { value, label, className, ...other } = props
  return (
    <FormLabel className={className}>
      <RadioInput value={value} {...other} type='radio' />
      <Label>{label}</Label>
    </FormLabel>
  )
}
export default Radio
