import React, { useState } from 'react'
import { FooterProps, NavigationProps } from '../../components'

export const MenuContext = React.createContext<{
  flatMenuData?: FooterProps
  navigationData?: NavigationProps
  setNavigationData?: Function
  setFlatMenuData?: Function
}>({})

export const MenuContextProvider = (props: {
  flatMenuData?: FooterProps
  navigationData?: NavigationProps
  children: any
}) => {
  const { flatMenuData, navigationData, children } = props
  const [flatMenu, setFlatMenu] = useState<FooterProps | undefined>(
    flatMenuData
  )
  const [navigation, setNavigation] = useState<NavigationProps | undefined>(
    navigationData
  )

  return (
    <MenuContext.Provider
      value={{
        flatMenuData: flatMenu,
        navigationData: navigation,
        setNavigationData: setNavigation,
        setFlatMenuData: setFlatMenu
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}
