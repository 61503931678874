import {
  PerformanceLocationProps,
  PerformanceLocationAPIProps
} from '../../services/api/types'
import { HeaderWithTitle } from '../header'
import { LocationCard } from './LocationCard'
import { ComingSoonSection } from './LocationCard.styled'
import * as G from '../../styles/global.styles'

export const PerformanceLocationCard = (props: PerformanceLocationProps) => {
  const { title, subTitle, secondTitle, gyms, coming_soon } = props
  return (
    <G.Section spacing={12}>
      <HeaderWithTitle headerText={title} />
      <G.FlexContainer
        flow='row'
        horizontalAlign='center'
        className='flex-wrap'
      >
        <h3>{subTitle}</h3>
      </G.FlexContainer>
      <G.FlexContainer
        flow='row'
        horizontalAlign='center'
        className='flex-wrap'
      >
        {gyms &&
          gyms.map((location, idx) => {
            return <LocationCard key={idx} {...location} />
          })}
      </G.FlexContainer>

      {coming_soon && (
        <ComingSoonSection>
          <h3>{secondTitle}</h3>
          <div>
            {coming_soon.map((stateName, idx) => (
              <div key={idx}>
                <h6>{stateName}</h6>
              </div>
            ))}
          </div>
        </ComingSoonSection>
      )}
    </G.Section>
  )
}

PerformanceLocationCard.ApiBlockToProps = (
  data: PerformanceLocationAPIProps
): PerformanceLocationProps => {
  const apiData = data.value
  const { title, gyms, coming_soon } = apiData

  return {
    title,
    subTitle: 'Join Now',
    gyms,
    secondTitle: 'Coming Soon',
    coming_soon
  }
}

export default PerformanceLocationCard
