import styled, { css } from 'styled-components'
import { DEVICES } from '../services/variables'
import tw from 'twin.macro'

const getColWidth = (number: number) => {
  return (number / 12) * 100
}

export const Col = styled.div<{
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xsOrder?: number;
  smOrder?: number;
  mdOrder?: number;
  lgOrder?: number;
  xlOrder?: number;
  flex?: boolean
}>`
  width: 100%;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}

  ${({ xs, xsOrder  }) =>
    xs &&
    css`
      @media ${DEVICES.xs} {
        width: ${getColWidth(xs)}%;
        flex: 0 0 ${getColWidth(xs)}%;
        order: ${xsOrder || 0};
      }
    `}

  ${({ sm, smOrder  }) =>
    sm &&
    css`
      @media ${DEVICES.sm} {
        width: ${getColWidth(sm)}%;
        flex: 0 0 ${getColWidth(sm)}%;
        order: ${smOrder || 0};
      }
    `}
    
  ${({ md, mdOrder }) =>
    md &&
    css`
      @media ${DEVICES.md} {
        width: ${getColWidth(md)}%;
        flex: 0 0 ${getColWidth(md)}%;
        order: ${mdOrder || 0};
      }
    `}

  ${({ lg, lgOrder  }) =>
    lg &&
    css`
      @media ${DEVICES.lg} {
        width: ${getColWidth(lg)}%;
        flex: 0 0 ${getColWidth(lg)}%;
        order: ${lgOrder || 0};
      }
    `}

  ${({ xl, xlOrder  }) =>
    xl &&
    css`
      @media ${DEVICES.xl} {
        width: ${getColWidth(xl)}%;
        flex: 0 0 ${getColWidth(xl)}%;
        order: ${xlOrder || 0};
      }
    `}
`

export const Row = styled.div<{
  itemsCenter?: boolean
  gutter?: number
  mobileGutter?: number
  tabletGutter?: number
  rowReverse?: boolean
  lg?: number
  xl?: number
}>`
  display: flex;
  flex-wrap: wrap;
  algin-items: flex-end;

  ${({ itemsCenter }) =>
    itemsCenter &&
    css`
      align-items: center;

      > ${Col} {
        height: 100%;
      }
    `}

  ${({ gutter }) =>
    gutter &&
    css`
      margin: 0 -${gutter}px;

      > ${Col} {
        padding: 0 ${gutter}px;
      }

      @media (max-width: 767px) {
        margin: 0 -15px;

        > ${Col} {
          padding: 0 15px;
        }
      }
    `}

    ${({ tabletGutter }) =>
      tabletGutter !== undefined &&
      css`
        @media (max-width: 1024px) {
          margin: 0 -${tabletGutter}px;

          > ${Col} {
            padding: 0 ${tabletGutter}px;
          }
        }
      `}

  ${({ mobileGutter }) =>
    mobileGutter !== undefined &&
    css`
      @media (max-width: 767px) {
        margin: 0 -${mobileGutter}px;

        > ${Col} {
          padding: 0 ${mobileGutter}px;
        }
      }
    `}

  ${({ rowReverse }) =>
    rowReverse &&
    css`
      flex-direction: row-reverse;
    `}
`

export const Container = styled.div<{
  fullWidthMobile?: boolean
  noPadding?: boolean
  fluid?: boolean
  size?: 'lg' | 'md'
}>`
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0 !important;
    `}


  ${({ fullWidthMobile }) =>
    fullWidthMobile &&
    css`
      padding: 0;
      overflow: hidden;
    `}

  @media ${DEVICES.md} {
    max-width: 100%;
    padding: 0 2.5rem;
  }

  @media ${DEVICES.lg} {
    max-width: calc(960px + 5rem);
  }

  @media ${DEVICES.xl} {
    max-width: calc(960px + 5rem);
  }

  ${({ fluid }) =>
    fluid &&
    css`
      max-width: 100% !important;
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      @media ${DEVICES.lg} {
        max-width: calc(1196px + 5rem);
      }
    
      @media ${DEVICES.xl} {
        max-width: calc(1196px + 5rem);
      }
    `}
`

export const Section = styled.section<{
  bgImage?: string
  pb?: string
}>`
  ${tw`bg-black__deep pb-20 w-full bg-cover`}

  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: ${pb};
    `}

  ${({ bgImage }) =>
    bgImage &&
    css`
    background-image: url('${bgImage}');
    box-shadow: inset 4000px 0 0 0 rgba(37, 51, 213, 0.90);
    ${tw`pb-0`}

    &:after {
      content: "";
      ${tw`block bg-black__deep h-20`}
    }
  `}
`
