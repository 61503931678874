import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import Modal from 'styled-react-modal'
import { Button } from '../base/Button'
import { navigationState } from './Modal'
import { LAYOUT_TEMPLATE } from '../../services/api/types'
import { PAGE_TEMPLATE } from '../../services/api/constants'

export const ModalHeadButton = styled.a<{
  navState: navigationState
  isHeroButton?: boolean
  isMobileButton?: boolean
}>`
  ${tw`uppercase border border-orange px-4 py-2 font-bold text-white hover:bg-orange transition-colors duration-300`}
  line-height: 1.75;

  @media (max-width: 767px) {
    ${tw`py-2`}
  }

  ${({ isHeroButton }) =>
    isHeroButton &&
    css`
      ${tw`leading-6 ml-4 my-2 px-4 lg:px-8 py-4 lg:py-2 transition-colors duration-300 uppercase italic cursor-pointer inline-block no-underline font-normal border-none bg-orange`}
      @media (max-width: 767px) {
        ${tw`py-2`}
      }
    `}
    ${({ isMobileButton }) =>
      isMobileButton &&
      css`
        ${tw`leading-6 transition-colors duration-300 uppercase italic cursor-pointer inline-block no-underline font-normal border-none bg-orange`}
        width:100vw;
        margin: 0;
        text-align: center;
      `}

  

    
  ${({ navState }) => {
    switch (navState) {
      case 'relative':
        return tw`border-white text-white hover:bg-orange hover:text-white hover:border-orange`
      case 'fixed':
        return tw`border-white text-white hover:bg-orange hover:text-white hover:border-orange`
      default:
        return tw`border-white text-white hover:bg-orange__dark hover:border-orange__dark hover:text-white xl:bg-orange xl:border-orange`
    }
  }}
`

export const ModalHeadButtonYouFitness = styled.a<{
  navState: navigationState
}>`
  ${tw`ml-4 my-2 py-2.5 font-medium text-sm px-8 transition-colors duration-300 cursor-pointer inline-block no-underline font-normal border-none capitalize`}
  line-height: 1.5;

  ${({ navState }) => {
    switch (navState) {
      case 'relative':
      case 'fixed':
      default:
        return tw`border-white text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`
    }
  }}
`

export const ModalBackdrop = styled.div`
  ${tw`fixed flex items-center justify-center`}
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(48, 56, 70, 0.75);
  overflow: auto;

  > div {
    position: initial;
  }

  .carousel .slide iframe {
    margin: 0 auto !important;
  }
`

export const ModalButton = styled(Button)`
  ${tw`mx-2 uppercase`}
  @media (max-width: 767px) {
    ${tw`py-2`}
  }
`

export const ModalHeader = styled.div<{ showUnderline?: boolean }>`
  ${tw`px-8 py-4 w-full text-center relative`}
  ${({ showUnderline }) => {
    return showUnderline ? tw`border-b` : ''
  }}
`

export const ModalCloseButton = styled.p<{ isNewTemplate?: boolean }>`
  ${tw`cursor-pointer absolute`}
  right: 20px;
  top: 18px;
  width: 32px;
  height: 32px;
  opacity: 0.3;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    ${({ isNewTemplate }) =>
      isNewTemplate ? `background-color: #FFF;` : `background-color: #333;`}
  }

  &:hover {
    opacity: 1;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

export const ModalBody = styled.div<{ isYouFitnessTemplate?: boolean }>`
  ${tw`px-8 py-4 w-full text-center`}

  ${({ isYouFitnessTemplate }) =>
    isYouFitnessTemplate &&
    css`
      background-color: rgb(27, 27, 27);
    `}

  @media (max-width: 768px) {
    overflow-y: scroll;
  }
  .standard-form,
  .member-enquiry {
    padding: 0;
  }

  &.generic {
    padding: 0;
  }
`

export const ModalText = styled.p`
  ${tw`text-center`}
`

export const ModalFooter = styled.div`
  ${tw`px-8 py-4 w-full text-center flex items-center justify-center`}
`

export const ModalTitle = styled.h4`
  ${tw`mb-0 text-white`}
`

export const ModalCloseIcon = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  cursor: pointer;
  position: absolute;
  top: 55%;
  right: 20px;
  font-size: 30px;
  transform: translate(0%, -50%);
  ${({ template }) => {
    return template === PAGE_TEMPLATE.YOU_FITNESS && tw`text-white`
  }}
`

//@ts-ignore
export const OverlayModal = Modal.styled<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`bg-white fixed items-center flex flex-col justify-between max-h-screen`}
  min-width: 300px;
  max-height: 90vh;

  @media (min-width: 768px) {
    min-width: 500px;
  }

  @media (min-width: 1024px) {
    min-width: 600px;
  }

  @media (min-width: 1280px) {
    min-width: 800px;
  }

  ${(
    // @ts-ignore
    { template }
  ) =>
    template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-black__deep`}

      ${ModalTitle} {
        ${tw`text-white`}
      }

      ${ModalHeader} {
        ${tw`border-black`}
      }

      .location-search-form {
        ${tw`bg-transparent`}
      }

      button {
        ${tw`px-8 py-2.5 not-italic normal-case text-sm`}
        line-height: 1.5;

        &.btn-cancel {
          ${tw`text-blue__deep`}
        }
      }
    `}
`
