import styled from 'styled-components'
import tw from 'twin.macro'
import dynamic from 'next/dynamic'
import { BaseTitle } from '../base'
const Select = dynamic(() => import('react-select'), {
  ssr: false
})

export const SubHeaderWrapper = styled.div`
  ${tw`relative lg:flex flex-col-reverse items-center my-8 md:flex-row w-full font-display`}
`

export const SortDropdownStyled = styled(Select)`
  ${tw`uppercase italic w-full order-1 flex-initial lg:order-2 lg:w-1/5`}

  & > div {
    overflow: initial;
    ${tw`rounded-none border-blue`}
    & > div > div {
      overflow: initial !important;
    }
  }
`

export const SubHeaderStyled = styled(BaseTitle)<{
  separator?: boolean
  hasDropdown?: boolean
}>`
  ${tw`text-blue__dark mb-0 lg:flex-1 text-center text-3xl py-4 uppercase font-extrabold order-2 lg:pl-56 lg:order-1`}

  ${props => !props.hasDropdown && tw`md:pl-0`}
`

export const ContentWrapper = styled.div`
  ${tw`flex flex-col lg:flex-row items-center`}
`
