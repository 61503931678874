import styled from 'styled-components'
import tw from 'twin.macro'
import { SVGIcon } from '../base'

export const FooterContainer = styled.div`
  ${tw`container mx-auto px-4 py-8  flex flex-col lg:flex-row flex-wrap justify-start lg:justify-between lg:items-center text-white`}
`

export const Logo = styled.img`
  ${tw`self-start order-2 lg:order-none mt-4 lg:mt-0`}
  height: 120px;
  width: auto;
`

export const Icon = styled(SVGIcon)`
  ${tw`hover:text-orange text-white`}
`

export const InfoGroup = styled.div`
  ${tw`order-3 lg:order-none flex flex-col text-sm mt-4 lg:mt-0`}
`

export const PartnerGroup = styled.div`
  ${tw`order-5 lg:order-none flex flex-col text-sm mt-4 lg:mt-0 `}
  p {
    ${tw`mb-0 `}
  }
`

export const Partner = styled.img`
  ${tw`self-start order-2 lg:order-none mt-0 lg:mt-0`}
  height: 44px;
  width: auto;
`

export const Copyright = styled.span`
  ${tw`block mb-1 italic`}
`
export const Address = styled.span`
  text-align:left;
`

export const LinkGroup = styled.div`
  ${tw`grid grid-cols-1 col-gap-8 row-gap-1 md:row-gap-0 md:grid-cols-2 lg:grid-cols-3 lg:col-gap-16 mt-4 `}
`
export const SocialGroup = styled.div`
  ${tw`order-1 lg:order-none mt-4 lg:mt-0`}
`

export const SocialLabel = styled.span`
  ${tw`text-xl font-bold`}
`

export const SocialIcons = styled.div`
  ${tw`mt-2 flex flex-row items-center text-xl`}
  & {
    a {
      ${tw`mr-2`}
    }
  }
`
