import cookie from 'js-cookie'
import clientCookie from 'cookie-cutter'

export const setCookie = (key: any, value: any, expires?: any) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: expires || 1
    })
  }
}

export const removeCookie = (key: any) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1
    })
  }
}

export const getCookie = (key: any, req: any) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req)
}

export const getCookieFromBrowser = (key: any) => {
  return clientCookie.get(key)
}

const getCookieFromServer = (key: any, req: any) => {
  if (!req) return null
  if (req.hasOwnProperty('req')) {
    req = req.req
  }

  if (!req.headers || !req.headers.cookie) {
    return undefined
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c: any) => c.trim().startsWith(`${key}=`))
  if (!rawCookie) {
    return undefined
  }

  let [cookieName, ...cookieData] = rawCookie.split('=')
  if (cookieName && cookieData && cookieData.length > 0) {
    return cookieData.join('=')
  }
  return undefined
}

export const CSRFTOKEN = 'csrftoken'
