const baseAPI = `/api/`

export default {
  WAGTAIL_API_BASE: baseAPI + 'cms/pages/',
  CMS_SETTINGS: baseAPI + 'cms/settings/',
  WAGTAIL_SLUG_FIND: baseAPI + 'cms/pages/find/',
  REDIRECTS: baseAPI + 'redirects/',
  WAGTAIL_PREVIEW: baseAPI + 'cms/page_preview/',
  GYMS_API_BASE: baseAPI + 'gyms/',
  MAIN_MENU_API: baseAPI + 'cms/main-menus/',
  FLAT_MENU_API: baseAPI + 'cms/flat-menus/',
  FORMS_API: baseAPI + 'cms/forms/',
  WORLDLINE_FORMS_API: baseAPI + 'worldline/forms',
  SITE: baseAPI + 'cms/sites/',
  OPPORTUNITIES_API_BASE: baseAPI + 'opportunities/'
}
