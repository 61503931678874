import { PAGE_TEMPLATE } from '../../services/api/constants'
import HeaderYouFitness from './YouFitnessTemplate/Header'
import Header from './Header'

const HeaderTemplate = (name?: string) => {
  switch (name) {
    case PAGE_TEMPLATE.YOU_FITNESS:
      return HeaderYouFitness

    default:
      return Header
  }
}

export default HeaderTemplate
