import React, { ImgHTMLAttributes } from 'react'
import { useInView } from 'react-intersection-observer'
import PlaceHolderImage from '../../static/imgs/placeholder.png'

export interface LazyImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  quickLoad?: boolean
}

export const LazyImage = (props: LazyImageProps) => {
  const { src, quickLoad } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <img
      {...props}
      ref={ref}
      src={!quickLoad && inView ? src : PlaceHolderImage}
    />
  )
}

LazyImage.defaultProps = {
  quickLoad: false
}
