import * as S from './LocationCard.styled'
import { LocationCardProps } from '../../services/api/types'

export const LocationCard = (props: LocationCardProps) => {
  const {
    title,
    address_postcode,
    address_state_code,
    address_street,
    address_suburb,
    franchise_status,
    phone_number,
    phone,
    full_url,
    linkTarget,
    template,
    cardId
  } = props

  const footerText = phone_number
    ? `${phone_number}`
    : phone
    ? `${phone}`
    : undefined
  const isOpenSoon = franchise_status === 'Opening Soon'
  return (
    <S.LocationCardStyled template={template} name={cardId}>
      <S.LocationInfo className='location-info'>
        <S.LocationTitleWrapper className='location-info-title-wrap'>
          {title && <S.LocationCardTitle>{title}</S.LocationCardTitle>}
          {isOpenSoon && (
            <S.LocationCardOpenSoon>{franchise_status}</S.LocationCardOpenSoon>
          )}
        </S.LocationTitleWrapper>
        <address>
          <p>{`${address_street &&
            address_street +
              ','} ${address_suburb} ${address_state_code} ${address_postcode}`}</p>
        </address>
        {footerText ? (
          <a href={`tel:${footerText}`}>{footerText}</a>
        ) : (
          <span>&nbsp;</span>
        )}
      </S.LocationInfo>
      <S.LocationLink href={full_url} target={linkTarget} />
    </S.LocationCardStyled>
  )
}

export default LocationCard
