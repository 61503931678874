import styled from 'styled-components'
import tw from 'twin.macro'

export const TextAreaInput = styled.textarea<{
  borderColor?: 'white' | 'orange' | 'black' | 'red'
  isFullWidth?: boolean
}>`
  ${tw`px-4`}
  ${({ borderColor }) => {
    switch (borderColor) {
      case 'white':
        return tw`border-white`
      case 'black':
        return tw`border-black`
      case 'orange':
        return tw`border-orange`
      case 'red':
        return tw`border-red__pinterest border-2`
      default:
        return ''
    }
  }}
  ${({ isFullWidth }) => {
    return isFullWidth ? tw`w-full` : null
  }}
`

export default TextAreaInput
