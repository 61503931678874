import styled from 'styled-components'
import tw from 'twin.macro'

export const HeaderWithTitleWrapper = styled.div`
  ${tw`w-full flex flex-col items-center pt-6 pb-4`}
`

export const HeaderAndSeparatorWrapper = styled.div`
  ${tw`container relative font-display`}
`

export const HeaderAndSeparatorStyled = styled.h2`
  ${tw`text-blue__dark flex-1 text-center text-3xl py-4 uppercase font-extrabold`}

  &::after {
    ${tw`absolute bottom-0 h-1 bg-orange`}
    content: " ";
    left: 50%;
    width: 185px;
    transform: translateX(-50%) rotate(-4deg);
  }
`

export const TextWrapper = styled.div`
  ${tw`container text-center px-2 mt-6 font-display italic pb-4`}
`
