import styled from 'styled-components'
import tw from 'twin.macro'
import AsyncSelect from 'react-select/async'
import { getAllGyms } from '../../services/api/cms'
import { generalItemsPerPage } from '../../services/api/constants'
import { LocationCardProps } from '../../services/api/types'
import { OptionTypeBase } from 'react-select'

const DropdownStyled = styled(AsyncSelect)`
  min-width: 16rem;
  placeholder: black;
  padding-top: 1px;
  padding-bottom: 1px;

  & > div {
    ${tw`rounded-none border-white px-4 lg:px-2`}
  }
`

export const GymsSelect = (props: OptionTypeBase) => {
  const fetchData = async (searchKey: string) => {
    const data = await getAllGyms({
      per_page: generalItemsPerPage,
      fields: 'title,id',
      q: searchKey,
      form_slug: props.formSlug
    })
    return data.results.map((gym: LocationCardProps) => {
      return {
        label: gym.title,
        value: gym.id
      }
    })
  }

  const loadOptions = (inputValue: string, callback: Function) => {
    fetchData(inputValue).then(res => {
      callback(res)
    })
  }
  return (
    <>
      <label>{props.label}</label>
      <DropdownStyled {...props} loadOptions={loadOptions} />
    </>
  )
}

export default GymsSelect
