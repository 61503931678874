const routes = require('next-routes')
module.exports = routes()
  .add({
    name: 'private_page',
    pattern: '/ask-password',
    page: 'authenticate'
  })
  .add({
    name: 'preview',
    pattern: '/preview/',
    page: 'preview'
  })
  // wagtail catchall
  .add({
    name: 'wagtail_catchall',
    pattern: '/:slug*',
    page: 'wagtail'
  })
