import React, { useState } from 'react'
import * as S from './Header.styled'
import { TextInput } from '../base/TextInput'
import * as G from '../../styles/global.styles'
import { UrlBuilder } from '../../services/utils'

export interface HeaderProps {
  CTATitle: string
  Titles: [string, string]
  buttonLabel: string
  buttonAction: string
  searchInputProps: object
  isMobile?: boolean
}

export const Header = (props: HeaderProps) => {
  const {
    CTATitle,
    Titles: [orangeText, whiteText],
    buttonLabel,
    searchInputProps,
    isMobile
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const onSearchClick = (e?: any) => {
    e.preventDefault()
    window.location.href = UrlBuilder('/gyms/', { q: searchTerm })
  }
  const textInputChanged = (newValue: any) => {
    setSearchTerm(newValue.target.value)
  }

  return (
    <G.Section bgColor='plus_header_grey'>
      <S.Container>
        <S.CTAGroup isMobile={isMobile}>
          {!isMobile && <S.ActionTitle>{CTATitle}</S.ActionTitle>}
          <S.FormWrapper onSubmit={onSearchClick} isMobile={isMobile}>
            <S.SearchGroup>
              <TextInput
                onChange={textInputChanged}
                {...searchInputProps}
                placeHolderColor='white'
              />
              <S.SearchButton type='submit' color='blue'>
                {buttonLabel}
              </S.SearchButton>
            </S.SearchGroup>
          </S.FormWrapper>
        </S.CTAGroup>
        {!isMobile && (
          <S.HeaderTitle>
            <S.TitleText color='orange'>{orangeText}</S.TitleText>
            <S.TitleText>{whiteText}</S.TitleText>
          </S.HeaderTitle>
        )}
      </S.Container>
    </G.Section>
  )
}
export default Header
