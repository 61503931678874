import * as G from '../../styles/global.styles'
import {
  HeaderWithTitleWrapper,
  HeaderAndSeparatorWrapper,
  TextWrapper
} from './HeaderWithTitle.styled'
import {
  WagtailBlockProps,
  Colors,
  FirstContentBlock
} from '../../services/api/types'
import { BlockTitle } from '../base'

export interface HeaderWithTitleProps extends FirstContentBlock {
  headerText: string
  titleText?: string
  background_colour?: Colors
}

export interface HeaderWithTitleAPIProps extends WagtailBlockProps {
  value: {
    background_colour: {
      background_colour?: Colors
    }
    headline: string
    featured_text?: string
    standard_text?: string
  }
}
export const HeaderWithTitle = (props: HeaderWithTitleProps) => {
  const { headerText, titleText, background_colour, isFirstBlock } = props

  return (
    <G.Section bgColor={background_colour}>
      <HeaderWithTitleWrapper>
        {headerText && (
          <HeaderAndSeparatorWrapper>
            <BlockTitle isSiteTitle={isFirstBlock}>{headerText}</BlockTitle>
          </HeaderAndSeparatorWrapper>
        )}
        {titleText && (
          <TextWrapper>
            <G.Text color='blue-dark' textCase='upper'>
              {titleText}
            </G.Text>
          </TextWrapper>
        )}
      </HeaderWithTitleWrapper>
    </G.Section>
  )
}

HeaderWithTitle.ApiBlockToProps = (data: HeaderWithTitleAPIProps) => {
  const { value, isFirstBlock } = data
  if (value) {
    const props: HeaderWithTitleProps = {
      headerText: value.headline,
      titleText: value.featured_text,
      background_colour: value.background_colour.background_colour,
      isFirstBlock: isFirstBlock
    }
    return props
  }
}

export default HeaderWithTitle
