import { useState } from 'react'
import DatePicker from 'react-datepicker'
import TextInput from './TextInput'
import moment from 'moment'
export interface DatePickerProps {
  name: string
  setFieldValue: Function
  showYearDropdown?: boolean
  placeholder?: string
  timePicker?: boolean
}
export const DatePickerStyled = (props: DatePickerProps) => {
  const { setFieldValue, name, showYearDropdown, placeholder, timePicker } = props
  const [selectedDate, setSelectedDate] = useState(null)
  const CustomInputDate = ({ value, onClick }: any) => (
    <TextInput
      onClick={onClick}
      value={value}
      borderColor='white'
      color='black'
      className='w-full'
      placeholder={placeholder || 'Select date'}
    />
  )
  const onDateChange = (date: any) => {
    setSelectedDate(date)
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD')
    setFieldValue(name, formattedDate)
  }
  const OnDateTimeChange = (date: any) => {
    setSelectedDate(date)
    const formattedDate = moment(date).format('llll')

    setFieldValue(name, formattedDate)
  }
  return (
    <>
    {timePicker ? (
      <DatePicker
        showTimeSelect
        dateFormat='d/M/yyyy h:mm a'
        selected={selectedDate || null}
        onChange={date => OnDateTimeChange(date)}
        popperPlacement="top-end"
        customInput={<CustomInputDate />}
        showYearDropdown={showYearDropdown}
      />
      ): (
        <DatePicker
        dateFormat='d/M/yyyy'
        selected={selectedDate || null}
        onChange={date => onDateChange(date)}
        customInput={<CustomInputDate />}
        showYearDropdown={showYearDropdown}
      />
      )}

    </>
  )
}

export default DatePickerStyled
