import styled from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../styles/theme'

export const LinkWrapper = styled.a`
  ${tw`w-full flex flex-col content-center justify-center items-center text-center`}
`

export const ImgWrapper = styled.div`
  ${tw`w-full text-center flex justify-center`}
`

export const ImgMaxFull = styled.img`
  ${tw`max-w-full`}
`

export const Text = styled.span`
  ${tw`py-4 uppercase text-2xl leading-6`}
`

export const SubtextWrapper = styled.div`
  ${tw`mb-8 px-2 lg:px-8 text-center`}
`

export const GymReadyStartedBtnJoin = styled.div`
  ${tw`w-full mx-auto relative`}
  max-width: 300px;
  margin-bottom: ${size(25)};

  @media (max-width: 768px) {
    max-width: ${size(327)};
  }

  a {
    ${tw`bg-blue__deep text-white transition-colors duration-300 flex items-center`}
    line-height: 2.5;
    padding: 0.35rem 2rem;
    font-weight: 500;

    figure {
      margin-bottom: 0;
      margin-right: ${size(10)};
      width: ${size(14)};
      height: ${size(14)};
    }

    &:hover {
      ${tw`bg-blue__dark2`}
    }
    height: ${size(78)};
    ${tw`w-full justify-center`}
    ${fontSize(24)}
  }
`

export const BlocksWrapper = styled.div`
  ${tw`w-full flex flex-wrap content-center justify-center items-stretch`}
`

export const Block = styled.div<{
  color?:
    | 'white'
    | 'blue'
    | 'blue-dark'
    | 'orange'
    | 'secondary'
    | 'primary'
    | 'secondary-alt'
    | string
}>`
  ${tw`w-full md:w-1/3 text-white p-12 flex items-center`}
  ${({ color }) => {
    switch (color) {
      case 'blue':
      case 'secondary':
        return tw`bg-blue`
      case 'blue-dark':
      case 'secondary-alt':
        return tw`bg-blue__dark`
      case 'orange':
      case 'primary':
        return tw`bg-orange`
      case 'white':
        return tw`bg-white`
    }
  }}
`
