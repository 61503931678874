import React from 'react'
import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { Colors } from '../../services/api/types'

export const BaseTitle = (props: {
  isSiteTitle?: boolean
  children?: any
  className?: string
  style?: CSSProperties
}) => {
  const { isSiteTitle, children, className, style } = props
  return isSiteTitle ? (
    <h1 className={className} style={style}>
      {children}
    </h1>
  ) : (
    <h2 className={className} style={style}>
      {children}
    </h2>
  )
}

export const BlockTitle = styled(BaseTitle)<{
  textColor?: Colors
  underlineColor?: Colors
  isYouFitness?: boolean
  fontSize?: 'lg' | 'md' | 'sm'
  textTransform?: 'uppercase' | 'lowercase' | 'normal' | 'capitalize'
}>`
  ${tw`font-bold text-center px-4 md:px-10 relative pb-4`}

  :after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 185px;
    height: 3px;
    transform: translateX(-50%) rotate(-4deg);

    ${({ underlineColor }) => {
      switch (underlineColor) {
        case 'black':
          return tw`bg-black`
        case 'white':
          return tw`bg-white`
        case 'blue':
          return tw`bg-blue`
        case 'blue-deep':
          return tw`bg-blue__deep`
        case 'blue-dark':
          return tw`bg-blue__dark`
        case 'blue-grey':
          return tw`bg-blue__grey`
        case 'blue-dodger':
          return tw`bg-blue__dodger`
        case 'orange':
          return tw`bg-orange`
        case 'orange-dark':
          return tw`bg-orange__dark`
        case 'green':
          return tw`bg-green`
        case 'grey':
          return tw`bg-grey`
        case 'grey-light':
          return tw`bg-grey__light`
        case 'grey-medium':
          return tw`bg-grey__medium`
        case 'grey-dark':
          return tw`bg-grey__dark`
        case 'silver':
          return tw`bg-silver`
        default:
          return tw`bg-orange`
      }
    }}
  }

  ${({ isYouFitness, fontSize }) =>
    isYouFitness &&
    css`
      ${tw`font-youfitnessHeading leading-tiny text-white uppercase pb-5`}

      &:after {
        ${tw`bg-blue__deep`};
        width: 70px;
        transform: unset;
        margin-left: -35px;
        height: 4px;
      }

      ${fontSize === 'sm' &&
        css`
          ${tw`pb-4`}
        `}
    `}

  ${({ textColor, isYouFitness }) => {
    switch (textColor) {
      case 'black':
        return tw`text-black`
      case 'white':
        return tw`text-white`
      case 'blue':
        return tw`text-blue`
      case 'blue-dark':
        return tw`text-blue__dark`
      case 'blue-grey':
        return tw`text-blue__grey`
      case 'blue-dodger':
        return tw`text-blue__dodger`
      case 'orange':
        return tw`text-orange`
      case 'orange-dark':
        return tw`text-orange__dark`
      case 'green':
        return tw`text-green`
      case 'grey':
        return tw`text-grey`
      case 'grey-light':
        return tw`text-grey__light`
      case 'grey-medium':
        return tw`text-grey__medium`
      case 'grey-dark':
        return tw`text-grey__dark`
      case 'silver':
        return tw`text-silver`
      default:
        return isYouFitness ? tw`text-white` : tw`text-blue__dark`
    }
  }}

  ${({ textTransform, isYouFitness }) => {
    switch (textTransform) {
      case 'uppercase':
        return tw`uppercase`
      case 'lowercase':
        return tw`text-white`
      case 'capitalize':
        return tw`capitalize`
      default:
        return isYouFitness ? tw`uppercase` : tw`normal-case`
    }
  }}

  ${({ fontSize }) => {
    switch (fontSize) {
      case 'lg':
        return tw`text-4xl`
      case 'md':
        return tw`text-4.5xl`
      case 'sm':
        return tw`text-3xl`
      default:
        return tw`text-4xl`
    }
  }}


  ${({ isYouFitness }) =>
    isYouFitness &&
    css`
      @media (max-width: 767px) {
        ${tw`text-2.5xl`}
      }
    `}

  &.width-full {
    ${tw`w-full`}
  }

  &.hero-with-form {
    padding: 0;
    margin-bottom: 0;
  }
`
