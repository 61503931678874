import {
  FirstContentBlock,
  LAYOUT_TEMPLATE,
  SelectOption,
  WagtailBlockProps
} from '../../services/api/types'
import {
  PAGE_TEMPLATE,
  SORT_OPTIONS,
  SORT_PLACEHODLER
} from '../../services/api/constants'
import * as S from './SubHeader.styled'
import {
  Container,
  Underline,
  UnderlineCustom
} from '../../styles/global.styles'

export interface SubHeaderProps extends FirstContentBlock {
  subHeaderText: string
  showDropdown: boolean
  showSeparator: boolean
  dropdownOptions?: Array<SelectOption>
  dropdownPlaceholder?: string
  onSortOptionChange?: Function
  template?: LAYOUT_TEMPLATE
}

export interface SubHeaderAPIProps extends WagtailBlockProps {
  value: {
    heading: string
  }
}

export const SubHeader = (props: SubHeaderProps) => {
  const {
    subHeaderText,
    dropdownPlaceholder,
    dropdownOptions,
    showDropdown,
    showSeparator,
    onSortOptionChange,
    isFirstBlock,
    template
  } = props

  const handleOptionChange = (event: SelectOption) => {
    onSortOptionChange && onSortOptionChange(event)
  }

  return (
    <S.SubHeaderWrapper>
      <Container position='relative' contentSize='lg'>
        <S.ContentWrapper>
          <S.SubHeaderStyled
            hasDropdown={showDropdown}
            separator={showSeparator}
            isSiteTitle={isFirstBlock}
          >
            {subHeaderText}
          </S.SubHeaderStyled>
          {showDropdown && (
            <S.SortDropdownStyled
              onChange={handleOptionChange}
              options={dropdownOptions || SORT_OPTIONS}
              placeholder={dropdownPlaceholder || SORT_PLACEHODLER}
            />
          )}
        </S.ContentWrapper>

        {template !== PAGE_TEMPLATE.YOU_FITNESS ? (
          <Underline color={undefined} className='mx-auto' skew={true} />
        ) : (
          <UnderlineCustom
            color={'blue-deep'}
            className='mx-auto'
            style={{ marginTop: 0 }}
          />
        )}
      </Container>
    </S.SubHeaderWrapper>
  )
}
SubHeader.ApiBlockToProps = (data: SubHeaderAPIProps) => {
  const { value, isFirstBlock } = data
  const props: SubHeaderProps = {
    subHeaderText: value.heading,
    showDropdown: false,
    showSeparator: true,
    isFirstBlock: isFirstBlock
  }
  return props
}

export default SubHeader
