import styled from 'styled-components'
import tw from 'twin.macro'

export const OriginalLocationResults = styled.div`
  ${tw`flex justify-center flex-wrap`}
`

export const CardWrap = styled.div`
  width: 26rem;
  ${tw`mx-2 mb-4`}

  @media (max-width: 943px) {
    ${tw`w-full`}
  }
`