import React from 'react'
import * as S from '../Header.styled'
import * as C from './Header.styled'
import { UrlBuilder } from '../../../services/utils'
import * as G from '../../../styles/global.styles'
import icon_search from '../../../static/imgs/icon-search.png'
import { HeaderProps } from '../Header'

export const HeaderNewGymTemplate = (props: HeaderProps) => {
  const {
    CTATitle,
    Titles: [orangeText, whiteText],
    isMobile
  } = props

  return (
    <G.Section className='h-top' bgColor='blue-deep'>
      <S.ContainWrap>
        {!isMobile && (
          <S.HeaderTitle>
            <C.TitleTextNewLayout>
              {orangeText} {whiteText}
            </C.TitleTextNewLayout>
          </S.HeaderTitle>
        )}
        <S.CTAGroup href={UrlBuilder('/gyms/', '')} isMobile={isMobile}>
          {!isMobile && (
            <C.ActionTitleNewLayout>{CTATitle}</C.ActionTitleNewLayout>
          )}
          <S.SearchLogo>
            <G.DefaultImg src={icon_search} alt={'search-icon'} />
          </S.SearchLogo>
        </S.CTAGroup>
      </S.ContainWrap>
    </G.Section>
  )
}

export default HeaderNewGymTemplate
