import { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const FileInputStyled = styled.div<{
  borderColor?: 'white' | 'orange' | 'black' | 'red'
}>`
    min-width: 200px;
    height: 40px;
    ${tw`border px-4 py-4 bg-white flex flex-col justify-center`}

    ${props => props.color === 'black' && tw`text-black`}
    ${({ borderColor }) => {
      switch (borderColor) {
        case 'white':
          return tw`border-white`
        case 'black':
          return tw`border-black`
        case 'orange':
          return tw`border-orange`
        case 'red':
          return tw`border-red__pinterest border-2`
      }
    }}
  `

export const FileInput = (props: any) => {
  const [textName, setTextName] = useState(`Upload ${props.text}`)
  const [fullTextName, setFulltextName] = useState('')
  const [isSelectedFile, setIsSelectedFile] = useState(false)

  const handleChange = (event: any) => {
    let selectedFileName = ''
    const fileName = event.currentTarget?.files[0]?.name
    if (fileName && fileName.length > 28) {
      selectedFileName =
        fileName.substring(0, 29) +
        '...' +
        fileName.substring(fileName.lastIndexOf('.'), fileName.length)
    } else selectedFileName = fileName
    if (fileName) {
      setIsSelectedFile(true)
    }
    setFulltextName(fileName)
    setTextName(selectedFileName)
    props.onChange(event)
  }

  return (
    <FileInputStyled className={props.className}>
      <label
        htmlFor='upload-file'
        className={`${isSelectedFile ? 'text-black' : 'text-silver__light'}`}
        title={fullTextName}
      >
        {textName}
      </label>
      <input
        {...props}
        onChange={handleChange}
        id='upload-file'
        className='opacity-0 absolute cursor-pointer'
      />
    </FileInputStyled>
  )
}

export default FileInput
