import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const TextInput = styled.input<{
  borderColor?: 'white' | 'orange' | 'black' | 'red',
  placeHolderColor?: 'white' | 'orange' | 'black' | 'red'
}>`

    height: 40px;
    ${tw`border border-blue px-4 py-4 text-white outline-none placeholder-silver__light`}
  
    ${props => props.color === 'black' && tw`text-black`}
    ${({ placeHolderColor }) => {
      switch (placeHolderColor) {
        case 'white':
          return css`
            ::placeholder {
              color: white;
              opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: white;
            }
          `
        case 'black':
          return css`
            ::placeholder {
              color: black;
              opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: black;
            }
          `
        case 'orange':
          return css`
            ::placeholder {
              color: orange;
              opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: orange;
            }
          `
        case 'red':
          return css`
            ::placeholder {
              color: red;
              opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: red;
            }
          `
      }
    }}
    ${({ borderColor }) => {
      switch (borderColor) {
        case 'white':
          return tw`border-white`
        case 'black':
          return tw`border-black`
        case 'orange':
          return tw`border-orange`
        case 'red':
          return tw`border-red__pinterest border-2`
      }
    }}
  `

export default TextInput
