import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Item = styled.span<{ active?: boolean; disabled?: boolean }>`
  ${tw`text-white block uppercase text-xs font-bold h-5 mb-2 pl-2 cursor-pointer`}
  line-height: 20px;

  ${props =>
    props.disabled &&
    css`
      ${tw`text-grey cursor-default`}
      pointer-event: none;
    `}
`


export const AlphabetList = styled.div`
  ${tw`absolute top-0 right-0 z-10`}
  width: 28px;
  display: none;
  height: calc(100vh - 100px);

  &.fixed {
    ${tw`fixed z-50`}
    top: 85px;
  }

  @media (max-width: 767px) {
    ${tw`block`}
  }

  a.active ${Item} {
    ${tw`bg-blue__deep`}
  }
`

