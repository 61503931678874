import styled from 'styled-components'
import tw from 'twin.macro'

import { ButtonProps } from '../../services/api/types'
import { ButtonLink } from '../../styles/global.styles'

const Icon = styled.img`
  height: 1em;
  ${tw`inline pr-2`}
`

const ButtonStyled = styled(ButtonLink)`
  ${tw`flex items-center justify-center px-4 lg:px-8 py-2 lg:py-4`}
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-xl lg:text-2xl`
      case '4xl':
        return tw`text-2xl lg:text-4xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
`

export interface ButtonWithIconProps extends ButtonProps {
  iconImgUrl?: string
}

export const ButtonWithIcon = (props: ButtonWithIconProps) => {
  const { iconImgUrl, ...baseButtonProps } = props
  return (
    <ButtonStyled {...baseButtonProps}>
      <Icon src={iconImgUrl} alt={baseButtonProps.label} />{' '}
      {baseButtonProps.label}
    </ButtonStyled>
  )
}
