import styled from "styled-components";
import tw from "twin.macro";
import {fontSize} from "../../../styles/theme";

export const TitleTextNewLayout = styled.span`
  ${tw`font-bold uppercase`}
  ${({ color }) => (color == 'orange' ? tw`text-orange` : tw`text-white`)}
    ${fontSize(12)}
  & {
    &:last-child {
      ${tw`ml-1`}
    }
  }
`

export const ActionTitleNewLayout = styled.span`
    ${fontSize(13)}
    ${tw`uppercase text-white font-bold mr-1 md:mr-3 xl:text-white text-xs lg:ml-8 xl:ml-0`}

    /* Extra Large (xl) */
    @media (max-width: 768px) {
      display: none; 
    }
`