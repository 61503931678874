import { UrlBuilder } from '../utils'
import { apiCall } from './helpers'
import { apis } from './'
const routes = require('../../routes')

export const getRedirects = async (path: string, ctx: any) => {
  let url = apis.REDIRECTS
  if (path) {
    url = UrlBuilder(url, { old_path: path })
  }
  return await apiCall(url, null, ctx)
}

export const getGymResults = async (values: any) => {
  await routes.pushRoute(UrlBuilder('/gyms/', { ...values }))
}
