import styled from 'styled-components'
import tw from 'twin.macro'
import Select from 'react-select'

export const DropdownStyled = styled(Select)`
  min-width: 16rem;
  @screen sm {
    min-width: 20rem;
  }
  placeholder: black;
  padding-top: 1px;
  padding-bottom: 1px;

  & > div {
    ${tw`rounded-none border-white px-4 lg:px-2 truncate`}
  }
`
export default DropdownStyled
